/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Admin, Resource, useLogout, Notification } from "react-admin";
import BookedSession from "./ui/pages/schedule/BookedSession/BookedSession";
import TransactionsList from "./ui/pages/transactions/AllTransaction";
import EligibleRefunds from "./ui/pages/transactions/EligibleRefunds";
import Subscriptions from "./ui/pages/transactions/Subscriptions/Subscriptions";
import PastRefunds from "./ui/pages/transactions/PastRefunds";
import Statistics from "./ui/pages/Statistics";
import "./App.css";
import customRoutes from "./routes/customRoutes";
import Coupons from "./ui/pages/discounts";
import Signup from "./ui/pages/onboarding";
import AllCustomers from "./ui/pages/customers/AllCustomers/AllCustomers";
import ListingCustomers from "./ui/pages/customers/ManageBookings/ListingCustomers";
import ContentTransactions from "./ui/pages/customers/CustomerTransactions/ContentTransactions";
import CustomerActivity from "./ui/pages/customers/CustomerTransactions/CustomerActivity";
import AutomatedMessages from "./ui/pages/communications/Customer";
import DynamicLinkList from "./ui/pages/payments/DynamicLinkList";
import AdLeads from "./ui/pages/customers/Leads/AdLeads";
import ContentList from "./ui/pages/schedule/RecordedContent/ContentList/index";
import authProvider from "./authProvider";
import history from "./index";
import { Layout } from "./ui/layout";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import MenuItem from "@material-ui/core/MenuItem";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Affiliates } from "./ui/pages/marketing/affiliates";
import { LimitedOffer } from "./ui/pages/limitedOffer";
import { isBrowserSupported } from "./utils/Utils";
import BrowserNotSupported from "./ui/modules/BrowserNotSupported";
import { api, dataProvider, resources } from "./data";
import PastEmails from "./ui/pages/communications/CustomEmail/PastEmails/PastEmails";
import ListWhatsappBroadcasts from "./ui/pages/whatsappBroadcast/list";
import DraftEmails from "./ui/pages/communications/CustomEmail/DraftEmails/DraftEmails";
import ScheduleEmail from "./ui/pages/communications/CustomEmail/ScheduledEmails/ScheduledEmails";
import Logs from "./ui/pages/Organisation/Logs";
import UserReferrals from "./ui/pages/marketing/UserReferrals";
import Referrals from "./ui/pages/Referrals";
import AllSegments from "ui/pages/customers/Segments/AllSegments";

import {
  checkUserData,
  fetchUserData,
  getAuthData,
  getSubscriptionPermission,
  getUtmDetails,
  setUtmDetails,
} from "./utils/AuthUtil";
import ExlyFeedbacks from "./ui/pages/ExlyFeedbacks";
import ManageFeedbacks from "ui/pages/ExlyFeedbacks/pages/ManageFeedbacks";
import PublishedFeedbacks from "ui/pages/ExlyFeedbacks/pages/PublishedFeedbacks";
import Blogs from "./ui/pages/communications/Blogs";
import ExlyFAQ from "./ui/pages/manageWebpage/FAQ";

import customSagas from "./redux/customSagas";
import customReducers from "./redux/customReducers";

import NotificationPopup from "ui/widgets/NotificationPopup";
import { is_empty } from "utils/validations";
import WebpageLeads from "./webpage-leads";
import NewListingCustomers from "ui/pages/customers/ManageBookings/SubscriptionCustomers";
import {
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";

import Reports from "ui/pages/Reports";
import BookedQuestions from "ui/pages/QuestionsPage/BookedQuestions";
import OrganizationV3 from "ui/pages/OrganizationV3/pages/OrgMembers";
import ZoomAttendanceHome from "ui/pages/ZoomAttendance/ZoomAttendanceHome";
import ZoomAttendanceOfListing from "ui/pages/ZoomAttendance/ZoomAttendanceOfListing";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
  app_route_titles,
} from "constants/urlPaths";

// Recorded content imports
import CourseAnalytics from "ui/pages/CourseAnalytics/CourseAnalytics";
import Leaderboard from "ui/pages/Leaderboard/Leaderboard";

import ManageRecordings from "ui/pages/schedule/ManageOffering/pages/ManageRecordings/ManageRecordings";
import ListWhatsappTemplates from "ui/pages/whatsappBroadcast/list/ListWhatsappTemplates";
import SalesPagesHome from "ui/pages/SalesPages/pages/SalesPagesHome/SalesPagesHome";
import ActiveDrip from "ui/pages/communications/CustomEmail/ActiveDrip/ActiveDrip";
import DraftDrip from "ui/pages/communications/CustomEmail/DraftDrip/DraftDrip";
import PausedDrip from "ui/pages/communications/CustomEmail/PausedDrip/PausedDrip";
import InActiveDrip from "ui/pages/communications/CustomEmail/InactiveDrip/InactiveDrip";
import AnalyticsPerEmail from "ui/pages/EmailDrip/pages/AnalyticsPerEmail/AnalyticsPerEmail";
import WebinarListingsAttendance from "ui/pages/ZoomAttendance/WebinarListingsAttendance";
import ContentResponses from "ui/pages/ContentResponses/ContentResponses";
import CampaignLeads from "ui/pages/customers/Leads/AdCampaignLeads";
import CampaignCustomers from "ui/pages/customers/Leads/AdCampaignCustomers/index.js";
import { whatsapp_broadcasts_apis } from "ui/pages/whatsappBroadcast/whatsappBroadCast.api";
import { BonusOfferingCustomers } from "ui/pages/customers/ManageBookings/BonusOfferingCustomers";
import { MySubscriptions } from "ui/pages/Account/pages/MySubscriptions";
import { SubscriptionTransactions } from "ui/pages/Account/pages/SubscriptionTransactions";
import { Ledger } from "ui/pages/Account/pages/Ledger";
import RefundHistory from "ui/pages/transactions/RefundHistory/RefundHistory";
import { EXLY_CONNECT_TABS } from "features/ExlyConnect/ExlyConnect.constants";
import { OndemandExlyConnect } from "features/ExlyConnect/pages/OndemandExlyConnect";
import { ScheduledExlyConnect } from "features/ExlyConnect/pages/ScheduledExlyConnect";
import { OfferingVariations } from "features/OfferingVariations";
import { CustomCodesWrapper } from "features/CustomCode/modules/CustomCodesWrapper";
import useDeploymentTimestampCheck from "hooks/useDeploymentTimestampCheck";
import {
  ALL_CUSTOMERS_ROUTE_IDS,
  ALL_CUSTOMERS_ROUTE_KEYS,
} from "features/Crm/modules/AllCustomers/AllCustomers.constants";
import {
  TRANSACTIONS_ROUTE_IDS,
  TRANSACTIONS_ROUTE_KEYS,
} from "features/Crm/modules/Transactions/Transactions.constants";
import {
  MY_BOOKINGS_ROUTE_IDS,
  MY_BOOKINGS_ROUTE_KEYS,
} from "features/Crm/modules/Bookings/modules/MyBookings/MyBookings.constants";
import {
  WHATSAPP_TEMPLATES_ROUTE_IDS,
  WHATSAPP_TEMPLATES_ROUTE_KEYS,
} from "features/WhatsappTemplate/WhatsappTemplate.constants";
import {
  PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS,
  PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS,
} from "features/PaymentLinks/modules/PaymentLinksTransactions/constants/PaymentLinksTransactions.constants";
import PaymentLinksTransactions from "features/PaymentLinks/modules/PaymentLinksTransactions/PaymentLinksTransactions";
import { PUBLIC_ROUTES } from "features/UILayout/constants/UILayout.constants";
import ExlyPreloader from "features/UILayout/ExlyPreloader";
import { AffliateManagement } from "features/AffiliateMarketing/modules/AffliateManagement/AffliateManagement";
import { NewAffiliateRequest } from "features/AffiliateMarketing/modules/NewAffiliateRequest/NewAffiliateRequest";
import { AffiliateDetails } from "features/AffiliateMarketing/modules/AffliateDetail/AffliateDetail";
import { AffliateTransactions } from "features/AffiliateMarketing/modules/AffliateTransactions/modules/AllTransactions/AffliateTransactions";
import { AffliateDiscount } from "features/AffiliateMarketing/modules/AffliateDiscount/AffliateDiscount";
import { OfferingWiseReport } from "features/AffiliateMarketing/modules/OfferingWiseReport/OfferingWiseReport";
import { PayoutSummary } from "features/AffiliateMarketing/modules/PayoutSummary/PayoutSummary";
import { handleRedirectToLoginPage } from "features/Onboarding/utils/Onboarding.utils";
import { AffliateTransactionsPayout } from "features/AffiliateMarketing/modules/AffliateTransactions/modules/TransactionPayout";

const generateClassName = createGenerateClassName({
  seed: "exly-creator-tool-",
});

const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
  allowMissing: true,
});

const LogoutBtn = () => {
  const logout = useLogout();
  return (
    <>
      <MenuItem
        style={{ color: "rgba(0, 0, 0, 0.54)" }}
        onClick={() => {
          window.location.href = `${window.location.origin}/#/myAccount`;
        }}
      >
        <AccountCircle style={{ margin: "0 15px 0 5px" }} /> My Account
      </MenuItem>
      <MenuItem
        style={{ color: "rgba(0, 0, 0, 0.54)" }}
        onClick={async () => {
          dataProvider.postAnalyticalEvent("creatorPage", "signOut", {
            flow: "creatorPage",
            funnel: "creatorPage",
            ui_component: "Text Button",
            identifier: "Logout",
          });
          // eslint-disable-next-line no-restricted-globals
          if (confirm("Are you sure you want to Logout?")) {
            const analytic = await dataProvider.postAnalyticalEvent(
              "creatorPage",
              "confirmSignOut",
              {
                flow: "creatorPage",
                funnel: "creatorPage",
                ui_component: "Button",
                identifier: "Confirm",
              }
            );
            if (analytic.status === 200) {
              logout();
            }
          } else {
            dataProvider.postAnalyticalEvent("creatorPage", "cancelSignOut", {
              flow: "creatorPage",
              funnel: "creatorPage",
              ui_component: "Button",
              identifier: "Cancel",
            });
            return;
          }
        }}
      >
        <ExitIcon style={{ margin: "0 15px 0 5px" }} /> Logout
      </MenuItem>
    </>
  );
};

// The purpose of this component is to consume the react-admin's context
const RaContextConsumer = () => {
  // Placing this effect in <App /> body causes it
  // to glitch and not work properly every time
  React.useEffect(() => {
    if (history) {
      history.listen(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      });
    }
  }, [history?.location]);

  return <></>;
};

// use this for lazy loading
// const LazyLoadRoutes = ({ LazyElement, props }) => {
//   return (
//     <React.Suspense fallback={<ExlyLoader />}>
//       <LazyElement {...props} />
//     </React.Suspense>
//   );
// };

const App = () => {
  const route = window.location.hash.split("?")[0];
  const params = window.location.hash.split("?")[1];
  const has_user_data = checkUserData();
  let not_root_page = route.length > 2;
  for (const item of PUBLIC_ROUTES) {
    if (route.includes(item) && route.length > 2) {
      not_root_page = false;
      break;
    }
  }
  useDeploymentTimestampCheck();
  React.useEffect(() => {
    (async () => {
      try {
        if (has_user_data) {
          await getSubscriptionPermission({ forceCall: true });
          return;
        }
        if (not_root_page) {
          if (is_empty(getAuthData())) {
            handleRedirectToLoginPage(params);
            return window.location.reload();
          } else {
            await fetchUserData();
            window.location.reload();
          }
        }
      } catch (err) {
        console.error("App []", err);
      }
    })();
  }, []);

  // @dev - if not root page and missing user data in session storage, showing loader screen while we fetch user data.
  if (!has_user_data && not_root_page) return <ExlyPreloader />;

  if (!isBrowserSupported()) return <BrowserNotSupported />;

  let location = window.location.href;
  location = location.replace("#/", "");

  const url = new URL(location);
  setUtmDetails(url);

  const utmDetails = getUtmDetails();
  if (utmDetails) {
    dataProvider.postAnalyticalEvent("creatorPage", "login", {
      flow: "brandPartnership",
      funnel: "creatorPage",
      exly_utm_info: utmDetails,
    });
  }

  window.addEventListener("online", () => {
    if (sessionStorage.getItem("no_internet")) {
      sessionStorage.removeItem("no_internet");
      window.location.reload();
    }
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Admin
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        customSagas={customSagas}
        customReducers={customReducers}
        loginPage={Signup}
        history={history}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        title="Exly"
        logoutButton={LogoutBtn}
        layout={Layout}
      >
        <Notification />
        <Resource
          name={
            TRANSACTIONS_ROUTE_IDS[TRANSACTIONS_ROUTE_KEYS.TRANSACTIONS_LIST]
          }
          list={TransactionsList}
          options={{ label: "Transactions" }}
        />
        <Resource
          name="notifications/automated-messages"
          list={AutomatedMessages}
          options={{ label: "Creator Comms" }}
        />
        <Resource
          name="host/statistics"
          list={Statistics}
          options={{ label: "Statistics" }}
        />
        <Resource
          name="payments/exly/refunds/eligible/list"
          list={EligibleRefunds}
          options={{ label: "Eligible Refunds" }}
        />
        <Resource
          name="payments/exly/subscription/list"
          list={Subscriptions}
          options={{ label: "Manage Subscriptions" }}
        />
        <Resource
          name={api.refund_history}
          list={RefundHistory}
          options={{ label: "Refund History" }}
        />
        <Resource
          name="payments/exly/refunds/past/list"
          list={PastRefunds}
          options={{ label: "Past Refunds" }}
        />
        <Resource
          name={api.web_leads}
          list={WebpageLeads}
          options={{ label: "Lead messages" }}
        />
        <Resource
          name="payments/exly/dynamiclinks/all"
          list={DynamicLinkList}
          options={{ label: "Payment Pages" }}
        />
        <Resource
          name={api.ad_leads}
          list={AdLeads}
          options={{ label: "Leads" }}
        />
        <Resource
          name={api.campaigns_leads}
          list={CampaignLeads}
          options={{ params }}
        />
        <Resource
          name={api.campaigns_customers}
          list={CampaignCustomers}
          options={{ params }}
        />
        <Resource
          name={api.content_purchases_list}
          list={ContentTransactions}
        />
        <Resource name={api.customer_activity} list={CustomerActivity} />
        <Resource name={api.fetch_draft_emails} list={DraftEmails} />
        <Resource
          name={app_route_ids[app_route_keys.scheduled_emails]}
          list={ScheduleEmail}
        />
        <Resource name={api.get_past_email} list={PastEmails} />
        <Resource
          name={app_route_ids[app_route_keys.active_drip]}
          list={ActiveDrip}
        />
        <Resource
          name={app_route_ids[app_route_keys.draft_drip]}
          list={DraftDrip}
        />
        <Resource
          name={app_route_ids[app_route_keys.paused_drip]}
          list={PausedDrip}
        />
        <Resource
          name={app_route_ids[app_route_keys.inactive_drip]}
          list={InActiveDrip}
        />
        <Resource
          name={resources.whatsapp_campaigns}
          list={ListWhatsappBroadcasts}
        />
        <Resource
          name={whatsapp_broadcasts_apis.list_campaigns}
          list={ListWhatsappBroadcasts}
        />
        <Resource
          name={
            WHATSAPP_TEMPLATES_ROUTE_IDS[
              WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list
            ]
          }
          list={ListWhatsappTemplates}
        />
        <Resource
          name={app_route_ids[app_route_keys.subscription_customer]}
          list={NewListingCustomers}
          options={{ label: "Offering class Customers" }}
        />
        <Resource name={"Blogs"} list={Blogs} />
        <Resource
          name={MY_BOOKINGS_ROUTE_IDS[MY_BOOKINGS_ROUTE_KEYS.MY_BOOKINGS_LIST]}
          list={BookedSession}
          options={{ label: "Booked Sessions" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.booking_questions]}
          list={BookedQuestions}
          options={{ label: "Booking Questions" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.listing_customer]}
          list={ListingCustomers}
          options={{ label: "Offering Customers" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.offering_variations]}
          list={OfferingVariations}
        />
        <Resource
          name={app_route_ids[app_route_keys.bonus_offering_customers]}
          list={BonusOfferingCustomers}
          options={{ label: "Bonus Offering Customers" }}
        />
        <Resource name={app_route_ids[app_route_keys.upsell_details]} />
        <Resource name={app_route_ids[app_route_keys.attendees_details]} />
        <Resource
          name={app_route_ids[app_route_keys.multiple_purchases_details]}
        />
        <Resource
          name={
            ALL_CUSTOMERS_ROUTE_IDS[ALL_CUSTOMERS_ROUTE_KEYS.CUSTOMERS_LIST]
          }
          list={AllCustomers}
          options={{ label: "All Customers" }}
        />
        <Resource
          name="payments/exly/recorded/bookings"
          list={ContentList}
          options={{ label: "Content List" }}
        />
        <Resource
          name="users/fetch/org/logs"
          list={Logs}
          options={{ label: "Organistaion Logs" }}
        />
        <Resource name="coupons" list={Coupons} />
        <Resource
          name={app_route_ids[app_route_keys.traffic_analysis]}
          list={Affiliates}
          options={{ label: "Affiliates" }}
        />
        <Resource
          name="host/offer/list"
          list={LimitedOffer}
          options={{ label: "Limited Offer" }}
        />
        <Resource
          name="payments/exly/user/referrals/list"
          list={UserReferrals}
          options={{ label: "User Referrals" }}
        />
        <Resource
          name="Reports"
          list={Reports}
          options={{ label: "Reports" }}
        />
        <Resource
          // Dummy route added, so that react admin table can be rendered.
          // We can safely remove this route, when an independent(of react admin) table render component is created
          name={`${api.listingSpecificCommunication.get_offering_details_sessions_by_id}`}
          list={WebinarListingsAttendance}
        />
        <Resource
          name={api.feedbacks.fetch_feedbacks_list}
          list={ExlyFeedbacks}
          options={{ label: "ExlyFeedbacks" }}
        />
        <Resource
          name={api.feedbacks.fetch_listing_feedbacks}
          list={ManageFeedbacks}
          options={{ label: "ExlyListingFeedbacks" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.listing_reviews]}
          list={PublishedFeedbacks}
          options={{ label: "ExlyListingFeedbacks" }}
        />
        <Resource
          name={api.faq_list}
          list={ExlyFAQ}
          options={{ label: "ExlyFAQ" }}
        />
        <Resource
          name="Referrals"
          list={Referrals}
          options={{ label: "Your Referrals" }}
        />
        <Resource name="host/custom/code/listing" list={CustomCodesWrapper} />
        <Resource
          name={app_route_ids[app_route_keys.organization]}
          list={OrganizationV3}
          options={{ label: "Organization" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.my_subscriptions]}
          list={MySubscriptions}
          options={{ label: "My Subscriptions" }}
        />
        <Resource
          name={app_pathnames[app_route_keys.exly_connect](
            EXLY_CONNECT_TABS.scheduled.value
          )}
          list={ScheduledExlyConnect}
        />
        <Resource
          name={app_pathnames[app_route_keys.exly_connect](
            EXLY_CONNECT_TABS.on_demand.value
          )}
          list={OndemandExlyConnect}
        />
        <Resource name={app_route_ids[app_route_keys.ledger]} list={Ledger} />
        <Resource
          name={app_route_ids[app_route_keys.subscription_transactions]}
          list={SubscriptionTransactions}
        />
        <Resource
          name="host/segments/list/customers"
          list={AllSegments}
          options={{ label: "Segments" }}
        />
        <Resource
          name="host/segments/list/leads"
          list={AllSegments}
          options={{ label: "Segments" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.zoom_attendance]}
          list={ZoomAttendanceHome}
          options={{ label: app_route_titles[app_route_keys.zoom_attendance] }}
        />
        <Resource
          name={app_route_ids[app_route_keys.live_webinar_attendance]}
          list={ZoomAttendanceOfListing}
        />
        <Resource
          name={app_route_ids[app_route_keys.zoom_attendance_of_listing]}
          list={ZoomAttendanceOfListing}
        />
        <Resource
          // tags: comment#1686134386972
          name={
            app_route_ids[
              app_route_keys.zoom_attendance_of_listing_for_customer
            ]
          }
        />
        <Resource
          name={app_route_ids[app_route_keys.course_analytics]}
          list={CourseAnalytics}
          options={{ label: "Course Analytics" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.manage_recordings]}
          list={ManageRecordings}
          options={{ label: "Manage Recordings" }}
        />

        <Resource
          name={app_route_ids[app_route_keys.leaderboard]}
          list={Leaderboard}
          options={{ label: "Leaderboard" }}
        />
        <Resource
          // dummy route for ExlyTable Modal
          name={app_route_ids[app_route_keys.leaderboard_breakdown_modal]}
        />
        <Resource
          name={app_route_ids[app_route_keys.recorded_content_responses]}
          list={ContentResponses}
          options={{ label: "Content Responses" }}
        />
        <Resource
          name={app_route_ids[app_route_keys.email_drip_analytics]}
          list={AnalyticsPerEmail}
        />
        <Resource
          name={app_route_ids[app_route_keys.sales_pages_home]}
          list={SalesPagesHome}
        />
        <Resource
          name={
            app_route_ids[
              app_route_keys.manage_branded_community_bonus_offerings
            ]
          }
        />
        <Resource
          name={app_route_ids[app_route_keys.new_user_req]}
          list={NewAffiliateRequest}
        />
        <Resource
          name={app_route_ids[app_route_keys.list_affliate_management]}
          list={AffliateManagement}
        />
        <Resource
          name={app_route_ids[app_route_keys.affliate_detail]}
          list={AffiliateDetails}
        />
        <Resource
          name={app_route_ids[app_route_keys.offerings_wise_report]}
          list={OfferingWiseReport}
        />
        <Resource
          name={app_route_ids[app_route_keys.payout_summary]}
          list={PayoutSummary}
        />
        <Resource
          name={app_route_ids[app_route_keys.discount_codes]}
          list={AffliateDiscount}
        />
        <Resource
          name={app_route_ids[app_route_keys.list_all_transcation]}
          list={AffliateTransactions}
        />
        <Resource
          name={app_route_ids[app_route_keys.list_all_payout_summary]}
          list={AffliateTransactionsPayout}
        />

        <Resource
          /**
           * tags: comment#1686134386972
           *
           * Dummy route added, so that react admin table can be rendered.
           * We can safely remove this route, when a new table component (independent of react admin) is created
           */
          name={
            app_route_ids[
              app_route_keys.manage_branded_community_members_purchases
            ]
          }
        />
        <Resource
          name={app_route_ids[app_route_keys.manage_branded_community_members]}
        />
        <Resource name={app_route_ids[app_route_keys.review_selection_modal]} />
        <Resource
          name={app_route_ids[app_route_keys.review_selection_list_modal]}
        />
        <Resource
          name={
            PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS[
              PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS.PAYMENT_LINKS_TRANSACTIONS
            ]
          }
          list={PaymentLinksTransactions}
          options={{ label: "Transactions" }}
        />
        {/* This component has to be placed inside Admin to be able to access the redux store context */}
        <NotificationPopup />
        <RaContextConsumer />
      </Admin>
    </StylesProvider>
  );
};

export default App;
